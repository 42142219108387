<script setup lang="ts">
import getSizes from "./getSizes";

const {
  src,
  sizes,
  loading = "lazy"
} = defineProps<{
  src: string;
  // height: number;
  // width: number;
  alt?: string;
  sizes?: string;
  loading?: "lazy" | "eager";
}>();

const sizesTransformed = computed(() => {
  return getSizes(sizes);
});

const srcset = computed(() => {
  return [`${src}/xs 48w`, `${src}/sm 256w`, `${src}/md 512w`, `${src}/lg 768w`, `${src}/xl 1024w`].join(
    ", "
  );
});
</script>

<template>
  <img :srcset :src :alt :loading decoding="async" :sizes="sizesTransformed" />
</template>
